<template>
  <div class="container">
    <van-pull-refresh
      v-model="isreFreshLoading"
      :success-text="refreshSuccessText"
      :success-duration="1500"
      @refresh="onRefresh"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="activeTime">
            <div>{{ item.activity_time }}</div>
            <div>{{ item.type_str }}</div>
          </div>
          <div class="axtiveInfo" @click="goDetail(item)">
            <div class="user-img">
              <img :src="item.image" alt="" />
            </div>
            <div class="info">
              <div class="info-title">
                <div class="num">{{ item.num_limit }}人</div>
                <div class="txt">{{ item.title }}</div>
              </div>
              <div class="info-time">
                <img src="../../assets/img/time.png" alt="" />
                <div>{{ item.activity_time }}</div>
              </div>
              <div class="info-address">
                <img src="../../assets/img/address.png" alt="" />
                <div class="van-ellipsis">{{ item.event_venue }}</div>
              </div>
            </div>
          </div>
          <div
            class="activeMoney"
            v-if="item.status == 1 || item.status == 2 || item.status == 3"
          >
            实付￥{{ item.pay_money }}
          </div>
          <div class="activeApply" v-if="item.status == 1">
            <div class="refund" @click="back_money(item)">退款</div>
            <div class="btn" @click="seeMa(item)">查看券码</div>
          </div>
          <!-- <div class="activeApply">
            <div class="refunding">退款中</div>
          </div> -->
        </div>
      </van-list>
    </van-pull-refresh>
    <!-- 消息弹窗 -->
    <van-dialog
      v-model="refundShow"
      title="确认退款"
      show-cancel-button
      @confirm="confirmRefund"
    >
      退款将会在24小时内原路返回
    </van-dialog>
  </div>
</template>

<script>
import { order_list, return_money } from "@/api/activity";
export default {
  props: {
    chan: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      list: [], //存贮列表数据
      loading: false, // 控制加载中 loading 状态
      finished: false, // 控制数据加载结束的状态
      error: false, // 控制列表加载失败的提示状态
      isreFreshLoading: false, // 控制下拉刷新的 loading 状态
      refreshSuccessText: "刷新成功", // 下拉刷新成功提示文本
      total: 0,
      pageSize: 20,
      page: 1,
      refundShow: false,
      back_id: null
    };
  },
  created() {
    console.log(this.chan, 985);
  },
  methods: {
    async onLoad() {
      const res = await order_list({
        type: this.chan.type,
        page: this.page,
        pageSize: this.pageSize
      });
      console.log(res.data.code, 211);
      if (res.data.code == 200) {
        const { orderList } = res.data.data;
        this.list.push(...orderList);
        this.loading = false;
        this.total = res.data.data.count;
        if (this.page * this.pageSize < this.total) {
          this.loading = true;
        } else {
          this.finished = true;
        }
        console.log(this.list, 985211);
      } else {
        //  this.loading = false;
        this.finished = true;
      }
    },
    // 下拉刷新会调用
    async onRefresh() {
      try {
        const res = await order_list({ type: this.chan.type });
        console.log(res);
        this.isreFreshLoading = false;
        this.list = [];
        const { orderList } = res.data.data;
        this.list.unshift(...orderList);
      } catch (err) {
        console.log(err);
      }
    },
    goDetail(item) {
      console.log(item, 365);
      this.$router.push({
        path: "/activityDetail",
        query: {
          id: item.activity_id
        }
      });
    },
    seeMa(item) {
      this.$router.push({
        path: "/check",
        query: {
          rec_id: item.id
        }
      });
      console.log(item, 654);
    },
    back_money(item) {
      this.refundShow = true;
      this.back_id = item.id;
    },
    async confirmRefund() {
      const res = await return_money({ rec_id: this.back_id });
      if (res.data.code == 200) {
        this.$toast("退款成功");
        this.onRefresh();
      } else {
        this.$toast(res.data.message);
      }
      console.log(res);
    } /*  */
  }
};
</script>
<style lang="scss" scoped>
/deep/.van-dialog__content {
  text-align: center;
  margin: 30px 0;
}
.item {
  box-sizing: border-box;
  width: 708px;
  // height: 473px;
  background-color: #fff;
  border-radius: 14px;
  margin: 21px auto;
  padding: 34px 27px 42px;
  .activeTime {
    font-size: 28px;
    color: #333333;
    margin-bottom: 42px;
    display: flex;
    justify-content: space-between;
  }
  .axtiveInfo {
    display: flex;
    .user-img {
      width: 174px;
      height: 174px;
      border-radius: 14px;

      margin-right: 14px;
      img {
        width: 174px;
        height: 174px;
        border-radius: 14px;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 12px 0;
      .info-title {
        display: flex;
        .num {
          max-width: 140px;
          padding: 0 5px;
          height: 33px;
          background: #f45b52;
          border-radius: 3px;
          text-align: center;
          line-height: 37px;
          color: #fff;
          margin-right: 8px;
        }
        .txt {
          //height: 31px;
          font-size: 33px;
          width: 380px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: Source Han Sans CN;
          font-weight: 600;
          color: #333333;
        }
      }
      .info-time {
        font-size: 25px;

        font-weight: 400;
        color: #999999;
        display: flex;
        img {
          width: 28px;
          height: 28px;
          margin-right: 8px;
        }
      }
      .info-address {
        font-size: 25px;
        font-weight: 400;
        color: #999999;
        display: flex;
        width: 460px;
        img {
          width: 28px;
          height: 28px;
          margin-right: 8px;
        }
      }
    }
  }
  .activeMoney {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 21px;
    color: #000000;
    font-size: 35px;
    font-weight: 600;
    .origin-price {
      font-size: 21px;
      text-decoration: line-through;
      color: #999999;
    }
    .icon {
      margin-left: 13px;
    }
    .vipprice {
      font-size: 42px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
    }
  }
  .activeApply {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 25px;
    .refund {
      width: 174px;
      height: 63px;
      background: #fff;
      border-radius: 31px;
      text-align: center;
      line-height: 65px;
      color: #333;
      border: 1px solid #999999;
      font-size: 28px;
    }
    .btn {
      width: 174px;
      height: 63px;
      background: #fff;
      border-radius: 31px;
      text-align: center;
      line-height: 65px;
      color: #333;
      border: 1px solid #999999;
      font-size: 28px;
      margin-left: 35px;
    }
    .refunding {
      width: 174px;
      height: 63px;
      background: linear-gradient(-30deg, #f7bc32 0%, #ffd449 98%);
      border-radius: 31px;
      text-align: center;
      line-height: 65px;
      color: #fff;
      font-size: 28px;
    }
    /* 
      /* .apply {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      } */
    /*  .btn {
        width: 174px;
        height: 63px;
        background: linear-gradient(-30deg, #f7bc32 0%, #ffd449 98%);
        border-radius: 31px;
        text-align: center;
        line-height: 65px;
      } */
  }
}
</style>
