<template>
  <div class="home">
    <!-- <van-nav-bar
      title="选择红娘"
      left-arrow
      @click-left="onClickLeft"
      :border="false"
    /> -->
    <van-tabs
      v-model="activeIndex"
      sticky
      background="#fff"
      title-inactive-color="#999999"
      title-active-color="#000000"
      @change="changeTab"
      swipeable
    >
      <van-tab
        v-for="(item, index) in tablist"
        :title="item.title"
        :key="index"
      >
        <activity-list :chan="item" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import activityList from "./components/activityList.vue";
export default {
  name: "myActivity",
  components: {
    activityList
  },
  /* props: {
    active: {
      type: String,
      require: true
    }
  }, */
  data() {
    return {
      activeIndex: 0 /* this.$route.query.active || 0 */,
      tablist: [
        { title: "全部", type: "" },
        { title: "已预约", type: "reserved" },
        { title: "待参加", type: "stayIn" },
        { title: "已使用", type: "used" },
        { title: "已退款", type: "returned" }
      ]
    };
  },
  mounted() {
    if (this.$route.query.active) {
      this.activeIndex = this.$route.query.active * 1;
    }
  },
  destroyed() {
    this.activeIndex = 0 * 1;
  },
  methods: {
    changeTab() {
      console.log(this.tablist[this.activeIndex].type, "切换了");
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  background-color: #f2f2f2;
  min-height: 100vh;
}
/deep/.van-nav-bar .van-icon {
  color: #333333;
}
/deep/.van-tabs__line {
  background: linear-gradient(-90deg, #ffeda3 0%, #fac33a 100%);
  position: absolute;
  bottom: 45px;
  width: 49px;
}
</style>
